
  import { defineComponent, inject, computed } from 'vue';  

  export default defineComponent({
    name: 'HomeView',
    setup() {
        const activeNavItem = inject("activeNavItem") as string;
        const updateNavItem = inject("updateNavItem") as any;
        const activeNav = computed({
            get: () => activeNavItem,
            set: updateNavItem,
        });
        return {
            activeNav
        };
    },
    async beforeCreate() {
        document.body.className = 'home';
    },
    async mounted() 
    {      
      document.documentElement.style.setProperty('--global-backcolor', "#1F1F1F");
    },    
  });
