
import Menu from './components/Menu.vue'
import { provide, reactive, computed } from 'vue';

export default {
  setup() {
    const state = reactive({
      activeNavItem: "99"
    });

    const updateNavItem = (item: string) => {
      state.activeNavItem = item;
    };

    provide(
      "activeNavItem",
      computed(() => state.activeNavItem)
    );
    provide("updateNavItem", updateNavItem);

    return { state };
  },
  components: {
    Menu
  }
}
