
    import { defineComponent, inject, computed } from 'vue';

    export default defineComponent({
    name: 'AW-menu',
    props: {
        awImgSrc: String,
        activeItem: String,
    },
    setup() {
        const activeNavItem = inject("activeNavItem") as string;
        const updateNavItem = inject("updateNavItem") as any;
        const activeNav = computed({
            get: () => activeNavItem,
            set: updateNavItem,
        });
        return {
            activeNav
        };
    },
    data() {
        return {
            showMobileNav: false,
            active: this.activeItem 
        }
    },
    methods: {
      toggleShowMobileNav() {
            this.showMobileNav = this.showMobileNav ? false : true;
      },
      setActiveItem(item: string) {
          this.active = item;
      },
      test() {
          console.log(this.activeNav);
      }
    }
    });
