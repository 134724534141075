import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$route.meta.hideNavbar)
      ? (_openBlock(), _createBlock(_component_Menu, {
          key: 0,
          awImgSrc: "AW1.svg",
          activeItem: "99"
        }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    }))
  ], 64))
}